import { useEffect, useState, lazy, Suspense } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavBar from '../component/navbar';
import Footer from '../component/footer';
import Logo1 from '../img/DAF-Logo.png';
import Logo2 from '../img/Ford-Logo.png';
import Logo3 from '../img/Iveco-Logo.png';
import Logo4 from '../img/Mercedes-Logo.png';
import Logo5 from '../img/Scania-Logo.png';
import Logo6 from '../img/Volkswagen-Logo.png';
import Logo7 from '../img/Volvo-Logo.png';
import '../index.css';
import 'swiper/css';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import axios from 'axios';
import { api } from '../controllers/api-controller';

const Veiculos = lazy(() => import('../component/veiculos-list'));

const Catalogue = () => {
    const [marcaFilter, setMarcaFilter] = useState('');
    const [modeloFilter, setModeloFilter] = useState('');
    const [anoFabricacaoFilter, setAnoFabricacaoFilter] = useState('');
    const [marcas, setMarcas] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Inicio | Osmani Caminhões";
        window.scrollTo(0, 0);
        fetchMarcas();
    }, []);

    const fetchMarcas = async () => {
        try {
            const response = await axios.get(`${api}/veiculos/marcas`);
            setMarcas(response.data.data);
        } catch (error) {
            console.error('Error fetching Marcas:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchModelos = async (selectedMarca) => {
        try {
            const response = await axios.get(`${api}/veiculos/modelos/${selectedMarca}`);
            setModelos(response.data.data);
        } catch (error) {
            console.error('Error fetching Modelos:', error);
        }
    };

    const handleMarcaChange = async (e) => {
        const selectedMarca = e.target.value;
        setMarcaFilter(selectedMarca);
        setModeloFilter('');

        try {
            // Clear existing modelos when Marca changes
            setModelos([]);
            if (selectedMarca) {
                await fetchModelos(selectedMarca);
            }
        } catch (error) {
            console.error('Error handling Marca change:', error);
        }
    };

    return (
        <div>
            <NavBar />
            <MDBContainer fluid className='banner-container'>
                <MDBRow fluid className='d-flex justify-content-center'>
                    <MDBCol md={12} className='banner-content'>
                        <h1><b>12 Anos de Excelência</b> </h1>
                        <h1>Sua Fonte Confiável para Caminhões</h1>
                        <h4>Compra, Venda, Troca e Financiamento</h4>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBContainer>
                <MDBRow className='filters'>

                    <MDBRow className='filters-container'>
                        <MDBCol size={12} className='filter-description'>
                            <h4 className='filter-description-title'>Busca instantânea</h4>
                            <p className='filter-description-tip'>Selecione os filtros que deseja para realizar uma busca instantânea.</p>
                        </MDBCol>
                        <MDBCol md={4}>
                            <div className='filter-object'>
                                <label>Marca</label>
                                <select value={marcaFilter} onChange={handleMarcaChange}>
                                    <option value=''>TODOS</option>
                                    {Array.isArray(marcas) ? (
                                        marcas.map((marca) => (
                                            <option key={marca} value={marca}>
                                                {marca}
                                            </option>
                                        ))
                                    ) : (
                                        <option value='' disabled>Loading...</option>
                                    )}
                                </select>
                            </div>
                        </MDBCol>
                        <MDBCol md={4}>
                            <div className='filter-object'>
                                <label>Modelo</label>
                                <select value={modeloFilter} onChange={(e) => setModeloFilter(e.target.value)}>
                                    <option value=''>TODOS</option>
                                    {Array.isArray(modelos) ? (
                                        modelos
                                            .filter((modelo, index, self) => self.findIndex((m) => m === modelo) === index) // Filter out duplicates
                                            .map((modelo) => (
                                                <option key={modelo} value={modelo}>
                                                    {modelo}
                                                </option>
                                            ))
                                    ) : (
                                        <option value='' disabled>Loading...</option>
                                    )}
                                </select>
                            </div>
                        </MDBCol>
                        <MDBCol md={4}>
                            <div className='filter-object'>
                                <label>Ano de fabricação</label>
                                <input
                                    type='text'
                                    value={anoFabricacaoFilter}
                                    onChange={(e) => setAnoFabricacaoFilter(e.target.value)}
                                    placeholder='Digite o ano aqui...'
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBRow>
                <MDBRow style={{ marginBottom: '5rem' }}>
                    <p style={{ fontSize: '0.9rem', marginTop: '2rem', color: '#555555', fontFamily: 'M PLUS 1', fontWeight: 600, textAlign: 'center' }}></p>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Veiculos
                            marcaFilter={marcaFilter}
                            modeloFilter={modeloFilter}
                            anoFabricacaoFilter={anoFabricacaoFilter}
                        />
                    </Suspense>
                </MDBRow>
            </MDBContainer>
            <MDBContainer style={{ maxWidth: '100%', paddingLeft: '0', paddingRight: '0' }}>
                <MDBRow style={{ maxWidth: '100%', paddingLeft: '0', paddingRight: '0', margin: '0' }}>
                    <MDBCol style={{ maxWidth: '100%', paddingLeft: '0', paddingRight: '0' }}>
                        <div style={{ backgroundColor: '#c0c0c0', width: '100%', height: '1rem' }}></div>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{ maxWidth: '100%', paddingLeft: '0', paddingRight: '0', margin: '0' }}>
                    <MDBCol style={{ maxWidth: '100%', paddingLeft: '0', paddingRight: '0' }}>
                        <div className='logo-carroussel-fade'>
                        </div>
                        <div style={{ backgroundColor: '#c0c0c0', marginTop: '1rem' }}>
                            <Swiper
                                className='swiper-container'
                                effect={'coverflow'}
                                autoplay={{ delay: 1200, disableOnInteraction: false, }}
                                coverflowEffect={{ depth: 500, slideShadows: false, rotate: 0, stretch: -170, scale: 1 }}
                                centeredSlides={true}
                                slidesPerView="5"
                                loop={true}
                                modules={[Autoplay, EffectCoverflow]}
                            >
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo1}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo2}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo3}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo4}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo5}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo6}></img>
                                </SwiperSlide>
                                <SwiperSlide className='slide-item'>
                                    <img src={Logo7}></img>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            
            <Footer />
        </div>
    );
}

export default Catalogue;