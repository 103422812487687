import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { VeiculoPreloader } from "../component/preloader";
import NavBar from '../component/navbar';
import Footer from '../component/footer';
import '../index.css';
import { AiOutlinePicLeft } from 'react-icons/ai';
import { CgCalendar } from 'react-icons/cg';
import { CgCalendarDates } from 'react-icons/cg';
import { GiCarWheel } from 'react-icons/gi';
import { GiSpeedometer } from 'react-icons/gi';
import { GiTruck } from 'react-icons/gi';
import { GiLargePaintBrush } from 'react-icons/gi';
import { IoLogoWhatsapp } from 'react-icons/io';
import { GoInfo } from "react-icons/go";
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { api } from "../controllers/api-controller";

function Veiculo() {
    const [data, setData] = useState(false);
    const [fotos, setFotos] = useState([]);
    const { slug_id } = useParams();

    async function fetchVeiculo(slugId) {
        try {
            const response = await fetch(api + `/veiculo/${slugId}`);
            if (!response.ok) {
                throw new Error(`Error fetching data. Status: ${response.status}`);
            }
            const veiculo = await response.json();
            return veiculo;
        } catch (error) {
            console.error('Error fetching data:', error.message);
            return { status: 'error', message: 'Failed to fetch data' };
        }
    }

    function formatarComoReais(valor) {
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    }

    useEffect(() => {
        fetchVeiculo(slug_id).then(veiculo => {
            if (veiculo.status !== 'not_found') {
                document.title = veiculo.data.NOME_VEICULO + ' | Osmani Caminhões';
                setData(veiculo.data);
    
                // Transformando as fotos em um array de objetos
                let fotosArray = veiculo.data.FOTOS.split(';').map(function (i) {
                    return { foto: i };
                }).filter(function (obj) {
                    return obj.foto; // Filtra elementos vazios
                });
    
                // Atualizando o estado com as fotos
                setFotos(fotosArray);
    
                console.log(data.ANO_MODELO);
                console.log(fotosArray);
            } else {
                setData(false);
            }
        });
    }, [slug_id]);

    return (
        <>
            <NavBar />
            {data ?
                <>
                    <MDBContainer fluid className='banner-container'>
                        <MDBRow fluid className='d-flex justify-content-center'>
                            <MDBCol md={12} className='banner-content'>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer className="info-container">
                        <MDBContainer style={{ padding: '1rem', marginTop: '-20%', backgroundColor: '#FFF', borderRadius: '15px' }}>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <MDBRow style={{ maxWidth: '100%', padding: '0', margin: '0' }}>
                                        <div style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '15px' }} className='carousel-custom'>
                                            {data && data.FOTOS && data.FOTOS.length > 0 && (
                                                <Swiper
                                                    className='swiper-veiculo-container'
                                                    autoplay={{ delay: 1200, disableOnInteraction: false, }}
                                                    centeredSlides={true}
                                                    slidesPerView="1"
                                                    loop={true}
                                                    modules={[Autoplay]}
                                                >
                                                    {fotos.map((item) =>
                                                        <SwiperSlide className='veiculo-slide' key={item.foto}>
                                                            <img src={`${api}/images/${data.ID}/${item.foto}`} alt={item.foto} height={'500px'} width={'100%'} style={{ objectFit: 'cover', borderRadius: '10px' }} />
                                                        </SwiperSlide>
                                                    )}
                                                </Swiper>
                                            )}
                                        </div>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol>
                                    <MDBRow style={{ maxWidth: '100%', height: '5rem', margin: '0', padding: '0', marginBottom: '6rem' }}>
                                        <MDBCol style={{ textAlign: 'left', color: '#000', marginTop: '3rem' }}>
                                            <h3 style={{ textTransform: 'uppercase' }}>
                                                {data.NOME_VEICULO}
                                            </h3>
                                            <h2 style={{ fontWeight: '400' }}>
                                                {formatarComoReais(parseFloat(data.VALOR_VEICULO))}
                                            </h2>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{ maxWidth: '100%' }}>
                                        <MDBCol>

                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <CgCalendar size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Ano do Veículo<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.ANO_VEICULO}</span></p>
                                            </div>


                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <CgCalendarDates size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Ano do Modelo<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.ANO_MODELO}</span></p>
                                            </div>


                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <GiCarWheel size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Tração<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.TRACAO}</span></p>
                                            </div>

                                        </MDBCol>
                                        <MDBCol>

                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <GiSpeedometer size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Quilometragem<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.QUILOMETRAGEM}</span></p>
                                            </div>


                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <GiTruck size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Carroceria<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.CARROCERIA}</span></p>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ padding: '5px', borderRadius: '50%', marginRight: '1.2rem' }}>
                                                    <GiLargePaintBrush size={'1.7rem'} color='#000' />
                                                </div>
                                                <p style={{ fontFamily: 'M PLUS 1', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem', color: '#000' }}>Cor<br />
                                                    <span style={{ fontSize: '0.9rem', fontWeight: 400 }}>{data.COR_VEICULO}</span></p>
                                            </div>

                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol className="d-flex justify-content-center">
                                            <MDBBtn onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=551143961937&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20${data.NOME_VEICULO}%20-%20${formatarComoReais(parseFloat(data.VALOR_VEICULO))}.%20Aguardo%20contato,%20obrigado!%20${window.location.href}`} color='white' className="wppBtnCheckout" outline><IoLogoWhatsapp size={'1.5rem'} />&nbsp; Faça sua proposta pelo WhatsApp</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <MDBContainer style={{ padding: '0' }}>
                            <MDBCol md={7}>
                                <MDBRow>
                                    <MDBCol>
                                        <h5 style={{ marginTop: '1rem', fontWeight: 600, color: '#1d3a6c' }} className="d-flex align-items-center"><AiOutlinePicLeft size={'1.5rem'} style={{ marginRight: '0.5rem', color: '#1d3a6c' }} />Detalhes do Veículo</h5>
                                        <p style={{ fontFamily: 'M PLUS 1', fontWeight: 400, fontSize: '0.9rem', textAlign: 'justify', whiteSpace: 'pre-wrap', marginTop: '2rem', color: '#000' }}>{data.DETALHES_VEICULO}</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBRow>
                                <MDBCol md={12}>
                                    <h3 style={{ borderBottom: 'solid 1px #1d3a6c', marginTop: '2rem', fontWeight: 600, color: '#1d3a6c' }} className="d-flex align-items-center"><GoInfo style={{ marginRight: '0.5rem', color: '#1d3a6c' }} />Informações</h3>
                                    <p style={{ fontFamily: 'M PLUS 1', fontWeight: 400, marginBottom: '2rem', whiteSpace: 'pre-wrap', marginTop: '2rem', color: '#000' }}>{data.INFORMACOES_VEICULO}</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBCol className="mobile-view" style={{ position: 'sticky', overflow: 'visible', position: '-webkit-sticky', bottom: '0rem', width: '100%', textAlign: 'center', borderTop: 'solid #1d3a6c 0.3rem' }}>
                                <MDBCard style={{ borderRadius: '0', boxShadow: '0 0 0', backgroundColor: '#1d3a6c80', backdropFilter: 'blur(15px)' }}>
                                    <MDBCardBody>
                                        <div className='ml-2' style={{ fontFamily: 'M PLUS 1' }}>
                                            <h4 style={{ fontWeight: 600, marginBottom: '0.5rem', color: '#fbfbfb' }}>Entre em contato!</h4>
                                            <MDBBtn onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=551143961937&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20${data.NOME_VEICULO}%20-%20${formatarComoReais(parseFloat(data.VALOR_VEICULO))}.%20Aguardo%20contato,%20obrigado!%20${window.location.href}`} color='white' style={{ border: 'none', borderRadius: '5px', width: '100%', fontSize: '0.8rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial', backgroundColor: '#00b74a' }} outline><IoLogoWhatsapp size={'1.5rem'} />
                                                &nbsp; Faça sua proposta pelo WhatsApp
                                            </MDBBtn>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBRow className="card-flutuante mobile-hidden" style={{ paddingTop: '0rem', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                <MDBCard style={{ borderRadius: '2px', marginTop: '0rem', boxShadow: '0 0 0', backdropFilter: 'blur(15px)' }}>
                                    <MDBCardBody>
                                        <div className='ml-2' style={{ marginRight: '1rem' }}>
                                            <h1 style={{ borderBottom: 'solid 0.2rem #1d3a6c', fontSize: '1.5rem', fontWeight: 500, marginBottom: '0.5rem', color: '#1d3a6c' }}>Interessado no Veículo?</h1>
                                            <p className='mt-2' style={{ fontFamily: 'M PLUS 1', fontWeight: 600, fontSize: '0.9rem', marginBottom: '1rem' }}>
                                                Entre em contato abaixo para discutir sobre ou fazer sua proposta!
                                            </p>
                                            <MDBRow style={{ alignContent: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <MDBCol>
                                                    <MDBBtn onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=551143961937&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20${data.NOME_VEICULO}%20-%20${formatarComoReais(parseFloat(data.VALOR_VEICULO))}.%20Aguardo%20contato,%20obrigado!%20${window.location.href}`} color='white' style={{ border: 'none', borderRadius: '5px', width: '60%', fontSize: '0.8rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial', backgroundColor: '#00b74a' }} outline><IoLogoWhatsapp size={'1.5rem'} />&nbsp;WhatsApp - (11) 4396-1937</MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBRow>
                        </MDBContainer>
                    </MDBContainer>
                </>
                :
                <VeiculoPreloader />
            }
            <Footer />
        </>
    );
}

export default Veiculo;