import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Index from './screen';
import SobreNos from './screen/sobre-nos';
import Dashboard from './screen/dashboard';
import Empresa from './screen/empresa';
import Veiculo from './screen/veiculo';
import Catalogue from './screen/catalogue';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Catalogue />} />
        <Route path="/veiculo/:slug_id" element={<Veiculo />} />
        <Route path="/nossa-historia" element={<SobreNos />}/>
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/catalogo" element={<Catalogue />} />
      </Routes>
{/*       {<FloatingWhatsApp phoneNumber={'+5514996028016'} avatar={LOGO} placeholder={'Digite uma mensagem...'} statusMessage={'Geralmente responde rápido'} accountName={'JT Empreendimentos'} chatMessage={'Olá, tudo bem? 🤝 \nPrecisa de ajuda?'} message={'Olá, vim através do site!'} />}
 */}    </BrowserRouter>
  );
}

export default App;
