import { useState, useEffect } from "react";
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { RegisterLoader } from "./preloader";
import { GalleryUpdate } from "./gallery_update";
import '../index.css';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { api } from '../controllers/api-controller';

function VeiculoRegister ({setView}) {

    const [step, setStep] = useState('introducao');
    const [error, setError] = useState('');
    const [id, setId] = useState('');
    const [nome_veiculo, setNome] = useState('');
    const [valor_veiculo, setValorVeiculo] = useState('');
    const [detalhes_veiculo, setDescricao] = useState('');
    const [ano_veiculo, setAnoVeiculo] = useState('');
    const [ano_modelo, setAnoModelo] = useState('');
    const [tracao, setTracao] = useState('');
    const [quilometragem, setQuilometragem] = useState('');
    const [carroceria, setCarroceria] = useState('');
    const [cor, setCor] = useState('');
    const [informacoes_veiculo, setInformacoesVeiculo] = useState('');
    const [cidade_localizacao, setCidadeLocalizacao] = useState('');
    const [modelo_veiculo, setModeloVeiculo] = useState('');
    const [marca_veiculo, setMarcaVeiculo] = useState('');

    const register = async () => {

        setStep('send-api');
        
        let bodyContent = JSON.stringify({
            "id": id,
            "nome_veiculo": nome_veiculo, 
            "detalhes_veiculo": detalhes_veiculo, 
            "ano_veiculo": ano_veiculo, 
            "ano_modelo": ano_modelo, 
            "tracao": tracao, 
            "quilometragem": quilometragem, 
            "carroceria": carroceria, // Adicionado
            "cor": cor, // Adicionado
            "informacoes_veiculo": informacoes_veiculo,
            "valor_veiculo": valor_veiculo, 
            "slug": nome_veiculo.replace(/[ /]/g, "-"),
            "cidade_localizacao": cidade_localizacao,
            "marca_veiculo": marca_veiculo,
            "modelo_veiculo": modelo_veiculo
        });
        

        let response = await fetch(api + "/createveiculo", { 
            method: "POST",
            body: bodyContent,
            headers: { 'Content-Type': 'application/json' }
        });
        
        let data = await response.json();
        
        if(data.status){
            setId(data.id_veiculo);
            console.log(id);
            setStep('fotos');
        }else{
            setError(true);
        }        
    }

   useEffect(()=> {
        document.title = 'Cadastrar Veiculo | Osmani Caminhoes';
    },[]);



    return(
        <>
                <MDBContainer>                
                    <MDBRow className="justify-content-center" style={{paddingBottom: '5rem', paddingTop: '5rem'}}>
                        <MDBCol md={10} className='text-center'>
                            <h4 style={{borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Cadastro de Veiculo</h4>
                            <p style={{fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Vamos lá? Basta seguir as instruções para fazer o cadastro do novo veiculo.</p>
                        </MDBCol>                    
                    {step === 'introducao' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Primeiro vamos identificar o veiculo</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo o nome do veiculo, a cidade e o valor.</p>
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite o nome do veiculo <b>*</b></label>
                                    <input placeholder="Nome do veiculo" defaultValue={nome_veiculo} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setNome(e.target.value)}/>

                                    <label className="mt-2" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o valor do veículo <b>*</b></label>
                                    <input placeholder="Exemplo: 1.500,00" defaultValue={valor_veiculo} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setValorVeiculo(e.target.value)}/>


                                    { nome_veiculo.length > 0 && valor_veiculo.length > 0 ?
                                        <MDBBtn onClick={() => setStep('vantagens')} className="mt-4" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#1d3a6c', color: '#1d3a6c'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'vantagens' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Legal, agora nos de os detalhes do veiculo</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo os detalhes (especificações) do veiculo e 
                                    dados como ano, ano do modelo, tração, quilometragem, carroceria e cor.</p>
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite os detalhes (especificações) do veiculo: <b>*</b></label>
                                    <textarea rows={5} placeholder="Apresente o veiculo para seu cliente..." defaultValue={detalhes_veiculo} className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setDescricao(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o ano do Veículo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 2000, 2010, 2014 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={ano_veiculo} onChange={e => setAnoVeiculo(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o ano do Modelo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 2000, 2010, 2014 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={ano_modelo} onChange={e => setAnoModelo(e.target.value)}/>

                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual cidade se encontra o veiculo??</label>
                                    <textarea rows={1} placeholder="Exemplo: 2000, 2010, 2014 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={cidade_localizacao} onChange={e => setCidadeLocalizacao(e.target.value)}/>

                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual a Marca do veiculo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 2000, 2010, 2014 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={marca_veiculo} onChange={e => setMarcaVeiculo(e.target.value)}/>

                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o modelo do veiculo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 2000, 2010, 2014 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={modelo_veiculo} onChange={e => setModeloVeiculo(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o tipo de tração do veículo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 4x4, 6x2 etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={tracao} onChange={e => setTracao(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual a quilometragem do veículo?</label>
                                    <textarea rows={1} placeholder="Exemplo: 295000km, 100000km etc" className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={quilometragem} onChange={e => setQuilometragem(e.target.value)}/>                                                                                                                                                                                    
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual a carroceria do veículo?</label>
                                    <textarea rows={1} placeholder="Exemplo: Baú furgão, Baú sider etc" className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={carroceria} onChange={e => setCarroceria(e.target.value)}/>                                                                                                                                                                                    
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual a cor do veículo?</label>
                                    <textarea rows={1} placeholder="Exemplo: Vermelho, Preto etc" className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={cor} onChange={e => setCor(e.target.value)}/>                                                                                                                                                                                        

                                    <MDBBtn className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#1d3a6c', color: '#1d3a6c'}} outline onClick={() => setStep('introducao')}><BsArrowLeft /> Introdução</MDBBtn>
                                    { detalhes_veiculo.length > 0 ?
                                        <MDBBtn onClick={() => setStep('outros-detalhes')} className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#1d3a6c', color: '#1d3a6c', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>     
                    : step === 'outros-detalhes' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Estamos quase lá...</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo informações adicionais sobre o veículo</p>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>De informações adicionais sobre o veículo <b>*</b></label>
                                    <textarea rows={5} placeholder="Informações adicionais..." style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setInformacoesVeiculo(e.target.value)}></textarea>
                                    
                                    <MDBBtn className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#1d3a6c', color: '#1d3a6c'}} outline onClick={() => setStep('vantagens')}><BsArrowLeft /> Vantagens</MDBBtn>
                                    { informacoes_veiculo.length > 0 ?
                                        <MDBBtn onClick={() => register()} className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#1d3a6c', color: '#1d3a6c', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'send-api' ?
                        <MDBCol md={10}>
                            <RegisterLoader />
                        </MDBCol>  
                    : step === 'fotos' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Agora falta apenas um passo...</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Para que seu veiculo fique visível, envie abaixo as fotos relacionadas a ele.</p>
                                    <GalleryUpdate id={id} setView={setView} />
                                </MDBCardBody>
                            </MDBCard>                            
                        </MDBCol>  
                    :                    
                        null
                    }                                 
                    </MDBRow>
                </MDBContainer>
        </>
    );
}

export default VeiculoRegister;