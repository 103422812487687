import { useState, useEffect } from "react";
import { MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBContainer, MDBModal, MDBModalDialog, MDBModalContent,
    MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import { VeiculosDashPreLoader, VeiculosSimpleListPreloader, DeleteLoader } from "./preloader";
import { EditVeiculo } from "./veiculo-edit";
import { IoEyeOutline, IoRefresh  } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { BsPencilSquare } from 'react-icons/bs';
import { FaRegTrashAlt, FaRoad } from 'react-icons/fa';
import { TbTrashOff } from 'react-icons/tb';
import '../index.css';
import { RiMapPinLine, RiCalendarLine } from "react-icons/ri";
import { GiCarWheel } from "react-icons/gi";
import { FiTruck } from "react-icons/fi";
import { api } from "../controllers/api-controller";
import CardImage from "./cardImage";

function VeiculoDashList() {

    const [data, setData] = useState({ veiculos: [] }); // Inicializa como um objeto com um array vazio de veículos
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [slug, setSlug] = useState('');
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [optionsStep, setOptionsStep] = useState('intro');
    const [basicModal, setBasicModal] = useState(false);
    const authKey = Cookies.get('authkey');

    const toggleShow = async () => {
        setBasicModal(!basicModal);
        setOptionsStep('intro');
        setDeleteStatus(false);
    }

    const veiculoSelect = (ID, nome_veiculo, slug) => {
        setId(ID);
        setNome(nome_veiculo);
        setSlug(slug);
        toggleShow();
    }

    const refreshList = async () => {

        try {
            setData(false);

            let response = await fetch(api + "/veiculos/platform", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    authkey: authKey,
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            let data = await response.json();
            setData(data);
            console.log(data)
        } catch (error) {
            console.error('Error:', error);
            // Tratamento adicional de erros pode ser feito aqui
        }
    }
    const deleteVeiculo = async () => {

        setDeleteStatus('waiting');

        let headersList = {
            mode: 'cors',
        }

        let bodyContent = JSON.stringify({ "ID": id, });

        try {

            let response = await fetch(api + "/deleteveiculo", {
                method: "POST",
                body: bodyContent,
                headersList
            });

            let data = await response.json();
            if (data.status) {
                setDeleteStatus('success');
                await refreshList();
            } else {
                setDeleteStatus('error');
            }

        } catch (e) {
            setDeleteStatus('error');
        }
    }

    useEffect(() => {
        refreshList();
    }, []);

    return (
        <>
            {data.veiculos ?
                <MDBContainer>
                    <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                        <MDBModalDialog>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle style={{ paddingLeft: '0.7rem', borderRadius: '4px', borderLeft: 'solid 0.4rem #1d3a6c', }}>{nome}</MDBModalTitle>
                                    <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBContainer>
                                        {optionsStep === 'intro' ?
                                            <MDBRow className="justify-content-center" style={{ paddingBottom: '1.6rem' }}>
                                                <MDBCol md={12}>
                                                    <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem' }} className='text-center'>Selecione uma das opções para continuar</p>
                                                </MDBCol>
                                                <MDBCol md={12}>
                                                    <MDBCard onClick={() => window.open('/veiculo/' + slug, '_blank')} className="hover-shadow" style={{ border: 'solid 1px #dcdcdc', borderRadius: '25px', }}>
                                                        <MDBCardBody>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem' }}>
                                                                    <IoEyeOutline size={'2rem'} color='#1d3a6c' />
                                                                </div>
                                                                <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                                                    <span style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem' }}>
                                                                        Visualizar Online
                                                                    </span> <br />
                                                                    Clique para acessar as opções para esse veiculo.</p>
                                                            </div>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                                <MDBCol md={12}>
                                                    <MDBCard onClick={() => setOptionsStep('editar')} className="hover-shadow mt-3" style={{ border: 'solid 1px #dcdcdc', borderRadius: '25px', }}>
                                                        <MDBCardBody>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem' }}>
                                                                    <BsPencilSquare size={'2rem'} color='#1d3a6c' />
                                                                </div>
                                                                <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                                                    <span style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem' }}>
                                                                        Editar Veiculo
                                                                    </span> <br />
                                                                    Clique para acessar as opções para esse veiculo.</p>
                                                            </div>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                                <MDBCol md={12}>
                                                    <MDBCard onClick={() => setOptionsStep('deletar')} className="hover-shadow mt-3" style={{ border: 'solid 1px #dcdcdc', borderRadius: '25px', }}>
                                                        <MDBCardBody>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem' }}>
                                                                    <FaRegTrashAlt size={'2rem'} color='#1d3a6c' />
                                                                </div>
                                                                <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                                                    <span style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem' }}>
                                                                        Excluir Veiculo
                                                                    </span> <br />
                                                                    Clique para acessar as opções para esse veiculo.</p>
                                                            </div>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            </MDBRow>
                                            : optionsStep === 'editar' ?
                                                <>
                                                    <EditVeiculo id={id} />
                                                    <MDBRow className="text-center">
                                                        <MDBCol>
                                                            <MDBBtn onClick={() => setOptionsStep('intro')} color="danger" style={{ width: '86.5%', marginTop: '1rem', marginBottom: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem', }} outline>
                                                                Fechar
                                                            </MDBBtn>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </>
                                                : optionsStep === 'deletar' ?
                                                    <MDBRow>
                                                        {deleteStatus === false ?
                                                            <>
                                                                <MDBCol md={12}>
                                                                    <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem' }} className='text-center'>Continuar com exclusão do veiculo?</p>
                                                                </MDBCol>
                                                                <MDBCol md={12}>
                                                                    <MDBCard onClick={() => deleteVeiculo()} className="hover-shadow mt-1" style={{ border: 'solid 1px #dcdcdc', borderRadius: '25px', }}>
                                                                        <MDBCardBody>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem' }}>
                                                                                    <FaRegTrashAlt size={'2rem'} color='#1d3a6c' />
                                                                                </div>
                                                                                <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                                                                    <span style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem' }}>
                                                                                        Continuar com exclusão
                                                                                    </span></p>
                                                                            </div>
                                                                        </MDBCardBody>
                                                                    </MDBCard>
                                                                    <MDBCard onClick={() => setOptionsStep('intro')} className="hover-shadow mt-3" style={{ border: 'solid 1px #dcdcdc', borderRadius: '25px', padding: '1rem' }}>
                                                                        <div>
                                                                            <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.7rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                                                                <span style={{ fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '0.8rem' }}>
                                                                                    <IoIosArrowBack size={'1.7rem'} color='#1d3a6c' /> Voltar para opções
                                                                                </span></p>
                                                                        </div>
                                                                    </MDBCard>
                                                                </MDBCol>
                                                            </>
                                                            : deleteStatus === 'waiting' ?
                                                                <DeleteLoader />
                                                                : deleteStatus === 'success' ?
                                                                    <MDBCol style={{ paddingTop: '3rem', paddingBottom: '3rem' }} className='text-center'>
                                                                        <FaRegTrashAlt style={{ width: '100px', height: '100px', color: '#1d3a6c' }} />
                                                                        <h4 style={{ marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, }}>Tudo Pronto</h4>
                                                                        <p style={{ marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem' }}>
                                                                            <i>O veiculo foi excluído com sucesso.</i></p>
                                                                    </MDBCol>
                                                                    : deleteStatus === 'error' ?
                                                                        <MDBCol style={{ paddingTop: '3rem', paddingBottom: '3rem' }} className='text-center'>
                                                                            <TbTrashOff style={{ width: '100px', height: '100px', color: '#1d3a6c' }} />
                                                                            <h4 style={{ marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, }}>Houve um erro</h4>
                                                                            <p style={{ marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem' }}>
                                                                                <i>Não foi possível excluir o veiculo. Tente novamente.</i></p>
                                                                        </MDBCol>
                                                                        :
                                                                        null
                                                        }
                                                    </MDBRow>
                                                    :
                                                    null
                                        }
                                    </MDBContainer>
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    <MDBRow style={{ marginBottom: '3rem' }}>
                        {data.veiculos.map((veiculos) =>
                            <MDBCol md={6} key={veiculos.ID}>
                                <MDBCard onClick={() => veiculoSelect(veiculos.ID, veiculos.NOME_VEICULO, veiculos.SLUG)} className="hover-shadow mt-3" style={{ border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px', }}>
                                    <MDBCardBody>
                                        <p style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem' }}>
                                            {veiculos.NOME_VEICULO}</p>
                                        <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem' }}>{veiculos.MODELO_VEICULO}</p>
                                        <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: 0 }}>
                                            Clique para acessar as opções para esse veiculo.</p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        )}
                    </MDBRow>
                </MDBContainer>
                :
                <MDBContainer style={{ paddingTop: '1rem' }}>
                    <VeiculosDashPreLoader />
                </MDBContainer>
            }
        </>
    );
}

function VeiculosSimpleList({ style, className }) {

    const [data, setData] = useState(false);

    useEffect(() => {
        (async () => {
            let headersList = {
                mode: 'cors',
            }

            let response = await fetch(api + "/veiculos", {
                method: "GET",
                headersList
            });

            let data = await response.json();
            setData(data);
        })();
    }, []);

    return (
        <>
            {data.veiculos ?
                <div style={style} className={className + ''}>
                    {data.veiculos.map((veiculos) =>
                        <p key={veiculos.ID}>
                            <a style={{ color: '#2B2F33', fontSize: '0.9rem' }} target='_blank' href={'/veiculo/' + veiculos.SLUG}>{veiculos.NOME_EMPREENDIMENTO}</a>
                        </p>
                    )}
                </div>
                :
                <VeiculosSimpleListPreloader style={style} className={className} />
            }
        </>
    );
}

const Veiculos = ({ style, className, marcaFilter, modeloFilter, anoFabricacaoFilter }) => {
    const [data, setData] = useState({ status: 'loading' });
    const [visibleItems, setVisibleItems] = useState(12); // Set the initial visible items

    const fetchData = async (limit) => {
        try {
            const endpoint = `${api}/veiculos/all?marca=${marcaFilter || ''}&modelo=${modeloFilter || ''}&anoFabricacao=${anoFabricacaoFilter || ''}&limit=${limit}`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const jsonData = await response.json();
            setData((prevData) => ({ status: 'success', veiculos: jsonData.data })); // Update here
        } catch (error) {
            console.error('Error fetching data:', error);
            setData({ status: 'error' });
        }
    };

    const handleLoadMore = () => {
        const newVisibleItems = visibleItems + 12; // Increase the visible items by 4 (adjust as needed)
        setVisibleItems(newVisibleItems);
        fetchData(newVisibleItems);
    };

    useEffect(() => {
        fetchData(visibleItems);
    }, [marcaFilter, modeloFilter, anoFabricacaoFilter]);

    console.log('Data:', data);

    return (
        <>
            {data.status === 'success' ? (
                <div style={style} className={className + ''}>
                    {data.veiculos && data.veiculos.slice(0, visibleItems).map((veiculo) => (
                        <MDBCol md={3} style={{ display: 'inline-block' }} key={veiculo.ID}>
                            <MDBCard className="shadow-1 card-hover" style={{ whiteSpace: 'normal', minHeight: '25rem', border: 'solid 1px #dcdcdc', margin: '0.4rem', borderRadius: '15px' }}>
                                <MDBCardBody style={{ backgroundColor: '#FFF', borderRadius: '15px' }}>
                                    <CardImage api={api} veiculo={veiculo} />
                                    <p className="title-veiculo" style={{ padding: '0.5rem', textTransform: 'uppercase', color: '#000', fontFamily: 'Gotham Light, sans-serif', fontWeight: 900, fontSize: '1rem', backgroundColor: '#FFF', width: '100%', marginBottom: '0', textAlign: 'center' }}>{veiculo.NOME_VEICULO}</p>
                                    <MDBRow className="d-flex justify-content-center align-items-center" style={{ padding: '1px', textAlign: 'left', margin: '0' }}>
                                        <MDBCol md={6} className="vehicle-card-info" style={{ whiteSpace: 'nowrap' }}>
                                            <GiCarWheel /> {veiculo.TRACAO}
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info" style={{ whiteSpace: 'nowrap' }}>
                                            <RiCalendarLine /> {veiculo.ANO_VEICULO}
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info" style={{ whiteSpace: 'nowrap' }}>
                                            <FaRoad /> {Number(veiculo.QUILOMETRAGEM).toLocaleString('pt-BR')} km
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info" style={{ whiteSpace: 'nowrap' }}>
                                            <FiTruck /> {veiculo.CARROCERIA}
                                        </MDBCol>
                                        <MDBCol md={12} className="vehicle-card-info" style={{ fontWeight: '700', whiteSpace: 'nowrap' }}>
                                            <RiMapPinLine /> {veiculo.CIDADE_LOCALIZACAO}
                                        </MDBCol>
                                        <MDBCol>
                                            <h4 style={{ padding: '10px 0px 5px 0px', color: '#1e1e1e', fontWeight: '500', textAlign: 'center' }}>{Number(veiculo.VALOR_VEICULO).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
                                        </MDBCol>
                                        <MDBBtn onClick={() => window.location.href = `/veiculo/${veiculo.SLUG}`} className="btn-acessar-veiculo">
                                            Saiba Mais
                                        </MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    ))}
                    <MDBContainer fluid className="d-flex justify-content-center" style={{ marginTop: '45px' }}>
                        <MDBRow fluid className="d-flex justify-content-center">
                            <MDBCol fluid className="d-flex justify-content-center">
                                <MDBBtn onClick={handleLoadMore} className={'default-custom-color btn-carregar-mais'}><IoRefresh /> Mostrar Mais</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            ) : data.status === 'error' ? (
                <div style={style} className={className + ''}>
                    {/* Handle error state */}
                </div>
            ) : (
                <div style={style} className={className + ''}>
                    {/* Handle loading state */}
                </div>
            )}
        </>
    );
}

const VeiculosMain = ({ style, className, marcaFilter, modeloFilter, anoFabricacaoFilter }) => {
    const [data, setData] = useState({ status: 'loading' });
    const [page, setPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [visibleItems, setVisibleItems] = useState(12); // Set the initial visible items

    const fetchData = async (limit) => {
        try {
            const endpoint = `${api}/veiculos/all?marca=${marcaFilter || ''}&modelo=${modeloFilter || ''}&anoFabricacao=${anoFabricacaoFilter || ''}&limit=${limit}`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }

            const jsonData = await response.json();
            setData((prevData) => ({ status: 'success', veiculos: jsonData.data })); // Update here
        } catch (error) {
            console.error('Error fetching data:', error);
            setData({ status: 'error' });
        }
    };

    const handleLoadMore = () => {
        setLoadingMore(true);
        const newVisibleItems = visibleItems + 12; // Increase the visible items by 4 (adjust as needed)
        setVisibleItems(newVisibleItems);
        fetchData(newVisibleItems);
        setLoadingMore(false);
    };

    useEffect(() => {
        fetchData(visibleItems);
    }, [marcaFilter, modeloFilter, anoFabricacaoFilter]);

    return (
        <>
            {data.status === 'success' ? (
                <div style={style} className={className + ''}>
                    {data.veiculos && data.veiculos.slice(0, visibleItems).map((veiculo) => (
                        <MDBCol md={3} style={{ display: 'inline-block' }} key={veiculo.ID}>
                            <MDBCard className="shadow-1 card-hover" style={{ whiteSpace: 'normal', height: '25rem', border: 'solid 1px #dcdcdc', margin: '0.4rem', borderRadius: '15px' }}>
                                <MDBCardBody style={{ backgroundColor: '#FFF', borderRadius: '15px', padding: '0' }}>
                                    <div className="vehicle-card" style={{ background: `url("${api}/images/${veiculo.ID}/${veiculo.FOTOS.split(';').filter(i => i)[0]}")`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                                    </div>
                                    <p className="text-truncate" style={{ padding: '0.7rem', textTransform: 'uppercase', color: '#000', fontFamily: 'Gotham Light, sans-serif', fontWeight: 900, fontSize: '1rem', backgroundColor: '#FFF', width: '100%', marginBottom: '0' }}>{veiculo.NOME_VEICULO}</p>
                                    <MDBRow style={{ padding: '1px', textAlign: 'center', margin: '0' }}>
                                        <MDBCol md={6} className="vehicle-card-info">
                                            <GiCarWheel /> {veiculo.TRACAO}
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info">
                                            <RiCalendarLine /> {veiculo.ANO_VEICULO}
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info">
                                            <FaRoad /> {Number(veiculo.QUILOMETRAGEM).toLocaleString('pt-BR')} km
                                        </MDBCol>
                                        <MDBCol md={6} className="vehicle-card-info">
                                            <FiTruck /> {veiculo.CARROCERIA}
                                        </MDBCol>
                                        <MDBCol md={12} className="vehicle-card-info" style={{ fontWeight: '700' }}>
                                            <RiMapPinLine /> {veiculo.CIDADE_LOCALIZACAO}
                                        </MDBCol>
                                        <MDBCol>
                                            <h4 style={{ padding: '10px 0px 10px 0px', color: '#1e1e1e', fontWeight: '500' }}>{Number(veiculo.VALOR_VEICULO).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
                                        </MDBCol>
                                        <MDBBtn onClick={() => window.location.href = `/veiculo/${veiculo.SLUG}`} style={{ textTransform: 'uppercase', borderRadius: '25px', fontWeight: 300, fontSize: '1rem', backgroundColor: '#1d3a6c', color: '#fff', padding: '2px' }} color='light'>
                                            saiba mais
                                        </MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    ))}
                </div>
            ) : data.status === 'error' ? (
                <div style={style} className={className + ''}>
                    {/* Handle error state */}
                </div>
            ) : (
                <div style={style} className={className + ''}>
                    {/* Handle loading state */}
                </div>
            )}
        </>
    );
}

export default Veiculos;

export { VeiculosMain, VeiculosSimpleList, VeiculoDashList };