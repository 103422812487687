import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'; 
import LogoImg from '../img/osmaniDefaultLogo.png';
import { BsWhatsapp } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { IoLogoInstagram } from 'react-icons/io';
import '../index.css';

function Footer() {
    return (
        <footer>
            <div style={{ backgroundColor: '#1d3a6c', width: '100%', height: '1rem', marginTop: '0rem' }}></div>
            <div style={{ backgroundColor: '#1d3a6c', width: '100%', paddingTop: '2rem', marginTop: '1rem' }}>
                <MDBContainer fluid>
                    <MDBRow className="text-center justify-content-center align-items-center" style={{ fontFamily: 'M PLUS 1', fontWeight: 600, }}>
                        <MDBCol md={3}>
                            <div style={{ fontSize: '0.8rem' }} className='text-start mt-2'>
                                <img src={LogoImg} style={{ width: '70%', position: 'relative' }} className='img-fluid' />
                            </div>
                        </MDBCol>
                        <MDBCol md={4}>
                            <div className="mt-2" style={{textAlign:`left`}}>
                                <h1 style={{ fontWeight: 600, fontSize: '1.5rem', fontFamily: 'M PLUS 1', color: '#f7f7f7' }}>Osmani Caminhões</h1>
                                <h6 className="mt-3" style={{ color: '#f7f7f7' }}>Fale Conosco</h6>
                                <a className="d-flex mt-1" href='https://api.whatsapp.com/send?phone=551143961937&text=Ol%C3%A1,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20Osmani%20Caminh%C3%B5es.'
                                    style={{ color: '#f7f7f7', fontSize: '0.9rem', fontWeight: '200' }}><BsWhatsapp size={'1.1rem'} style={{ color: '#25D366' }} />&nbsp; (11) 4396-1937 </a>
                                <a className="d-flex mt-2" href='https://api.whatsapp.com/send?phone=5511986663820&text=Ol%C3%A1,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20Osmani%20Caminh%C3%B5es.'
                                    style={{ color: '#f7f7f7', fontSize: '0.9rem', fontWeight: '200' }}><BsWhatsapp size={'1.1rem'} style={{ color: '#25D366' }} />&nbsp; (11) 9 8666-3820</a>

                                <h6 className="mt-4" style={{ color: '#f7f7f7' }}>Onde Estamos</h6>
                                <p className="mt-1" style={{ color: '#f7f7f7', fontSize: '0.9rem', fontWeight: '200' }}>
                                    <a style={{ color: '#f7f7f7' }} href="https://www.google.com/maps/place/Osmani+%26+Fernandes+Caminhões/@-23.7433724,-46.5550004,15z/data=!4m2!3m1!1s0x0:0xdf5ca9af2ed94e95?sa=X&ved=2ahUKEwjGi6G147SCAxWdA7kGHUv-BD0Q_BJ6BAhYEAA" target="_blank" rel='noreferrer'>Av. Nicola Demarchi, 195, Demarchi
                                        <br />São Bernardo do Campo/SP
                                        <br />CEP: 09820-655</a>
                                </p>
                            </div>
                        </MDBCol>
                        <MDBCol md={3}>
                            <div className="mt-2" style={{textAlign:`left`}}>
                                <h6 style={{ fontWeight: 600, fontFamily: 'M PLUS 1', color: '#f7f7f7' }}>Horário de Atendimento</h6>
                                <p style={{ color: '#f7f7f7', fontSize: '0.9rem', fontWeight: '200' }}>Segunda à Sexta <br /> 08:00 às 18:00</p>
                                <p style={{ color: '#f7f7f7', fontSize: '0.9rem', fontWeight: '200' }}>Sábado <br /> 08:00 às 12:00</p>
                            </div>
                            <div className="mt-5" style={{textAlign:`left`}}>
                                <a href='https://www.facebook.com/osmanicaminhoes.oficial?locale=pt_BR' style={{
                                    textDecoration: 'none', color: '#f7f7f7',
                                    border: 'solid 2px #f7f7f7', borderRadius: '50%', display: 'inline-flex', justifyContent: 'left', alignItems: 'left',
                                    padding: '15px', margin: '0 0.3rem'
                                }}><FaFacebookF size={'1.3rem'}></FaFacebookF></a>
                                <a href='https://www.instagram.com/osmani_caminhoes/' style={{
                                    textDecoration: 'none', color: '#f7f7f7',
                                    border: 'solid 2px #f7f7f7', borderRadius: '50%', display: 'inline-flex', justifyContent: 'left', alignItems: 'left',
                                    padding: '15px', margin: '0 0.3rem'
                                }}><IoLogoInstagram size={'1.3rem'}></IoLogoInstagram></a>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-center align-items-end" style={{ borderTop: 'solid 0.5px #f7f7f7', marginTop: '3rem', paddingTop: '1rem' }}>
                        <MDBCol>
                            <p className="text-center" style={{ color: '#f7f7f7', fontSize: '0.75rem', }}>© {(new Date().getFullYear())} OSMANI & FERNANDES
                                CAMINHOES LTDA - CNPJ: 15.276.623/0001-69. Todos os Direitos Reservados.</p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </footer>
    );
}



function FooterDashboard() {
    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted' style={{ bottom: 0, marginTop: '1rem' }}>

            <div className='text-center p-4' style={{ borderTop: 'solid 1px #d9d9d9', color: '#242424', fontSize: '0.7rem', fontWeight: '600' }}>
                <a href='https:/agenciaupstairs.com.br' target='_blank' rel='noreferrer'>
                    © {new Date().getFullYear()} - Desenvolvido por <a target='_blank' href='https://agenciaupstairs.com.br' rel='noreferrer'>Agência Upstairs</a>
                </a>
            </div>
        </MDBFooter>
    );
}

export { FooterDashboard };

export default Footer;