import React, { useState, useEffect } from 'react';

import IMG_PLACEHOLDER from '../img/Osmani-Empresa.jpg';

function CardImage({ api, veiculo }) {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

  // Função para verificar a imagem e definir o fundo
  const verificarEAtualizarImagemDeFundo = (imageUrl) => {
    const testImage = new Image();
    testImage.onload = () => setBackgroundImageUrl(imageUrl);
    testImage.onerror = () => setBackgroundImageUrl(IMG_PLACEHOLDER);
    testImage.src = imageUrl;
  };

  useEffect(() => {
    const primeiraFoto = veiculo.FOTOS.split(';').filter(Boolean)[0];
    if (!primeiraFoto) {
      setBackgroundImageUrl(IMG_PLACEHOLDER);
      return;
    }
    const imageUrl = `${api}/images/${veiculo.ID}/${primeiraFoto}`;
    verificarEAtualizarImagemDeFundo(imageUrl);
  }, [api, veiculo]);

  return (
    <div
      className="vehicle-card-image"
      style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
    ></div>
  );  
}

export default CardImage;
