import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../index.css';
import { GalleryUpdateLoader } from './preloader';
import { useDropzone } from 'react-dropzone';
import { AiFillPicture } from 'react-icons/ai';
import { BsCheck2Circle } from 'react-icons/bs';
import { BiErrorAlt } from 'react-icons/bi';
import { api } from "../controllers/api-controller";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  height: '20rem',
  borderWidth: 2,
  borderRadius: 30,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

function GalleryUpdate({ id, setView }) {

  const [statusLoading, setStatusLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const style = useMemo(() => ({
    ...baseStyle,
  }), []);

  const [files, setFiles] = useState([]);
  const [accept, setAccept] = useState(undefined)
  const veiculoID = id;

  const { getRootProps, getInputProps, } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setAccept(acceptedFiles);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const moveItem = (direction, index) => {
    if (direction === "up" && index > 0) {
      const newFiles = [...files];
      [newFiles[index], newFiles[index - 1]] = [newFiles[index - 1], newFiles[index]];
      setFiles(newFiles);
    } else if (direction === "down" && index < files.length - 1) {
      const newFiles = [...files];
      [newFiles[index], newFiles[index + 1]] = [newFiles[index + 1], newFiles[index]];
      setFiles(newFiles);
    }
  };

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name} className='thumbs-holder'>
      <div style={thumbInner} className='thumbs-img'>
        <img src={file.preview} style={img} onLoad={() => { URL.revokeObjectURL(file.preview) }} />
      </div>
      <div className='thumbs-buttons'>
        {index > 0 && (
          <button onClick={() => moveItem("up", index)}>←</button>
        )}
        {index < files.length - 1 && (
          <button onClick={() => moveItem("down", index)}>→</button>
        )}
      </div>
    </div>
  ));

  const uploadImages = async () => {
    if (!veiculoID) return;

    setStatusLoading(true);

    let bodyContent = new FormData();

    // Aqui, iteramos sobre `files` diretamente, assegurando que a ordem no estado é a ordem de envio
    files.forEach(file => {
      bodyContent.append('fotos[]', file, file.name);
    });
    bodyContent.append('id', veiculoID);

    try {
      let response = await fetch(api + "/updategallery", {
        method: "POST",
        body: bodyContent,
      });

      let data = await response.json();

      if (data.status === true) {
        setUpdated(true);
      } else {
        setUpdated('error');
      }
    } catch (e) {
      setUpdated('error');
    } finally {
      setStatusLoading(false);
    }
  };

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      {statusLoading ?

        <GalleryUpdateLoader />

        :
        <>
          {!updated ?
            <section className="container">
              <div {...getRootProps({ style, className: 'dropzone' })}>
                <input {...getInputProps()} />
                <h4 style={{ marginTop: '15%' }} className='text-center'><AiFillPicture /> Clique ou solte as fotos do veiculo aqui...</h4>
              </div>
              <aside style={thumbsContainer} className='preview-gallery'>
                {thumbs}
              </aside>
              <MDBBtn onClick={uploadImages} color={'primary'} style={{ marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem', width: '100%' }} disabled={!accept ? true : false}><BsCheck2Circle size={'1.1rem'} />&nbsp;&nbsp; Salvar e concluir</MDBBtn>
            </section>
            :
            <>
              {updated !== 'error' ?
                <MDBRow className='justify-content-center'>
                  <MDBCol style={{ paddingTop: '3rem', paddingBottom: '3rem' }} className='text-center'>
                    <BsCheck2Circle style={{ width: '100px', height: '100px', color: '#1d3a6c' }} />
                    <h4 style={{ marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, }}>Tudo Pronto</h4>
                    <p style={{ marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem' }}>
                      <i>As fotos enviadas já estão disponíveis na página do veiculo.</i></p>
                    <MDBBtn onClick={() => setView('gerenciar_veiculo')} style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', borderRadius: '20px', backgroundColor: '#1d3a6c' }}>Acessar veiculos</MDBBtn>
                  </MDBCol>
                </MDBRow>
                :
                <MDBRow className='justify-content-center'>
                  <MDBCol style={{ paddingTop: '3rem', paddingBottom: '3rem' }} className='text-center'>
                    <BiErrorAlt style={{ width: '100px', height: '100px', color: '#1d3a6c' }} />
                    <h4 style={{ marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, }}>Houve um erro...</h4>
                    <p style={{ marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem' }}>
                      <i>Houve um erro ao processar as imagens em nosso servidor, tente novamente.</i></p>
                    <MDBBtn onClick={() => setUpdated(false)} style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', borderRadius: '20px', backgroundColor: '#1d3a6c' }}>Tentar novamente</MDBBtn>
                  </MDBCol>
                </MDBRow>
              }
            </>
          }
        </>
      }
    </>
  );
}


export { GalleryUpdate };