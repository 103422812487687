import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { DashboardPreloader } from "../component/preloader";
import { NavbarDashboard } from "../component/navbar";
import { FooterDashboard } from "../component/footer";
import { VeiculoDashList } from "../component/veiculos-list";
import VeiculoRegister from "../component/veiculo-register";
import '../index.css';
import { IoMdLogIn } from "react-icons/io";
import { IoMailOpenOutline } from "react-icons/io5";
import { IoMdLock } from "react-icons/io";
import { TiWarningOutline } from "react-icons/ti";
import { api } from "../controllers/api-controller";

const authKey = Cookies.get('authkey');

async function authSession(key) {
    try {
        let response = await fetch(api + "/authsession", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key: key,
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
}

async function login(user, pass) {
    try {
        let response = await fetch(api + "/auth", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail: user,
                pass: pass
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }


        return response.json();
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
}


function Dashboard() {
    const [view, setView] = useState('loading');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginWarning, setLoginWarning] = useState({ status: false, message: '' });
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        async function checkAuth() {
            try {
                const response = await authSession(authKey);
                if (response && response.auth === false) {
                    console.log(response);
                    setView('login');
                } else {
                    setView('dashboard');
                }
            } catch (error) {
                console.error('Error during authentication:', error);
                // Tratamento adicional de erros pode ser feito aqui
            }
        }

        checkAuth();
    }, []);

    const handleLogin = async () => {
        if (!loginLoading) {
            setLoginLoading(true);
            const response = await login(username, password);
            if (response.status) {
                setLoginWarning({ status: false, message: '' })
                Cookies.set('authkey', response.authKey, { expires: 7 });
                setLoginLoading(false);
                setView('dashboard');
            } else {
                setLoginWarning({ status: true, message: response.message })
                setLoginLoading(false);
            }
        }
    }

    return (
        <>
            { view !== 'login' && view !== 'loading' && <NavbarDashboard setView={setView} />}
            { view === 'dashboard' ?
                <>
                    <MDBContainer>
                        <MDBRow className="justify-content-center" style={{ paddingBottom: '5rem', minHeight: '73vh', paddingTop: '5rem' }}>
                            <MDBCol md={10}>
                                <h4 style={{ borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600 }}>Bem vindo(a) a sua área de gerenciamento, o que deseja fazer agora?</h4>
                                <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600 }}>Escolha uma das opções abaixo para começar.</p>
                            </MDBCol>
                            <MDBCol md={5}>
                                <MDBCard onClick={() => setView('adicionar_veiculo')} className="hover-shadow" style={{ border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem' }}>
                                    <MDBCardBody style={{ background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem' }}>
                                        <h6 style={{ borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600, }}>Adicionar novo veiculo</h6>
                                        <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600 }}>Clique aqui para adicionar um novo veiculo a plataforma.</p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md={5}>
                                <MDBCard onClick={() => setView('gerenciar_veiculo')} className="hover-shadow" style={{ border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem' }}>
                                    <MDBCardBody style={{ background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem' }}>
                                        <h6 style={{ borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600, }}>Gerenciar veiculos</h6>
                                        <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600 }}>Clique aqui para visualizar, editar ou excluir veiculo cadastrados na plataforma.</p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </>
                : view === 'adicionar_veiculo' ?
                    <VeiculoRegister setView={setView}/>
                : view === 'gerenciar_veiculo' ?
                    <>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md={10} style={{paddingTop: '5rem'}}>
                                    <h4 style={{ borderLeft: 'solid 0.4rem #1d3a6c', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600,}}>Gerenciamento de veiculos cadastrados</h4>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600 }}>Escolha uma veiculo para pode visualizar suas opções de gerenciamento.</p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <VeiculoDashList />
                    </>
                : view === 'login' ?
                    <MDBContainer style={{ backgroundColor: '#1d3a6c' }} fluid>
                                <MDBRow className="justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                                    <MDBCol md={4}>
                                        <MDBCard style={{ borderRadius: '15px' }}>
                                            <MDBCardBody>
                                                <h1 style={{ fontSize: '1.2rem' }}>
                                                    <span style={{ padding: '0.5rem 1rem', borderRadius: '30px', color: '#1d3a6c', backgroundColor: '#ccdaff', display: 'inline-flex', marginBottom: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                                                        <IoMdLogIn style={{ marginRight: '0.5rem' }} size={'1.5rem'} />Fazer login
                                                    </span>
                                                </h1>
                                                <p style={{ fontSize: '0.9rem', fontWeight: 400 }}>Para visualizar e gerenciar os veículos cadastrados, por favor, insira suas informações de login nos campos
                                                    abaixo.
                                                </p>
                                                <label style={{ fontWeight: 600, fontSize: '0.8rem' }} className="d-flex align-items-center"><IoMailOpenOutline style={{ marginRight: '0.5rem', color: '#1d3a6c' }} size={'1rem'} />Seu e-mail:</label>
                                                <input type="text" name='user' placeholder="digite aqui seu usuário" id='user' style={{ borderRadius: '10px' }} className="form-control mb-2 mt-1" onChange={e => setUsername(e.target.value)} />

                                                <label style={{ fontWeight: 600, fontSize: '0.8rem' }} className="d-flex align-items-center"><IoMdLock style={{ marginRight: '0.5rem', color: '#1d3a6c' }} size={'1rem'} />Sua senha: </label>
                                                <input type="password" name='password' placeholder="digite aqui sua senha" style={{ borderRadius: '10px' }} id='password' className="form-control mb-3 mt-1" onChange={e => setPassword(e.target.value)} />

                                                {loginWarning.status && <p style={{ fontSize: '0.9rem', color: 'red' }}><TiWarningOutline style={{ marginRight: '0.5rem' }} />{loginWarning.message}</p>}

                                                <MDBBtn style={{ backgroundColor: '#1d3a6c', borderRadius: '25px', textTransform: 'initial', fontSize: '0.9rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} onClick={handleLogin}>
                                                    {!loginLoading ?
                                                        'Acessar Dashboard'
                                                        :
                                                        <MDBSpinner color='light' size="sm">
                                                            <span className='visually-hidden'>Loading...</span>
                                                        </MDBSpinner>
                                                    }
                                                </MDBBtn>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                    </MDBContainer>
                :
                    <DashboardPreloader />
            }
            { view !== 'login' && view !== 'loading' && <FooterDashboard />}
        </>
    );
}

export default Dashboard;