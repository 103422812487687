import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBSpinner } from 'mdb-react-ui-kit';
import '../index.css';

function  DashboardPreloader(){
    return (
        <>
            <MDBContainer style={{maxWidth: '90%'}}>                
                <MDBRow className='justify-content-center'>    
                    <MDBCol md={6} style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                        <MDBSpinner style={{width: '100px' , height: '100px', color: '#1d3a6c'}} role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Sua Plataforma de Gerenciamento</h4>
                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                            <i>Aqui você pode adicionar, visualizar, editar ou excluir os veículo que serão exibidos na plataforma.</i></p>
                    </MDBCol> 
                </MDBRow>
            </MDBContainer> 
        </>
    );
}

function  RegisterLoader(){
    return (
        <>
            <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                <MDBSpinner style={{width: '100px' , height: '100px', color: '#1d3a6c'}} role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
                <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Agora só aguardar, estamos terminando...</h4>
                <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                    <i>Nossa plataforma está fazendo o registro do veículo em nossos bancos de dados. Não feche seu navegador ou desconecte seu dispositivo.</i></p>
            </MDBCol> 
        </>
    );
}

function  DeleteLoader(){
    return (
        <>
            <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                <MDBSpinner style={{width: '100px' , height: '100px', color: '#1d3a6c'}} role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
                <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Agora só aguardar, estamos terminando...</h4>
                <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                    <i>Nossa plataforma está excluindo o registro do veículo em nossos bancos de dados. Não feche seu navegador ou desconecte seu dispositivo.</i></p>
            </MDBCol>        
        </>
    );
}

function  GalleryUpdateLoader(){
    return (
        <>
                <MDBRow className='justify-content-center'>    
                    <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                        <MDBSpinner style={{width: '100px' , height: '100px', color: '#1d3a6c'}} role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Aguarde alguns instantes</h4>
                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                            <i>Nossa plataforma está fazendo o registro das novas fotos do veículo em nossos bancos de dados. Não feche seu navegador ou desconecte seu dispositivo.</i></p>
                    </MDBCol> 
                </MDBRow>
        </>
    );
}

function  VeiculoPreloader(){
    return (
        <>
            <MDBContainer style={{maxWidth: '90%'}}>                
                <MDBRow className='justify-content-center'>    
                    <MDBCol md={6} style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                        <MDBSpinner style={{width: '100px' , height: '100px', color: '#1d3a6c'}} role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <h2 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, color:'#1d3a6c'}}>Osmani Caminhões</h2>
                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}><i>Compra, Venda, Troca e Financiamento de Caminhões. Há 12 Anos no Mercado.</i></p>
                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}><i>Negocie seu caminhão com a garantia de quem já vendeu mais de 2.000 e financiou mais de 10.000 caminhões.</i></p>
                    </MDBCol> 
                </MDBRow>
            </MDBContainer> 
        </>
    );
}

function VeiculosDashPreLoader(){


    return (    
        <MDBRow className='justify-content-center'>
            <MDBCol md={4}>
                <MDBCard className="shadow-1 card-loading-width mt-4" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', borderRadius: '15px'}}>
                    <MDBCardBody style={{borderRadius: '15px'}}>
                        <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                        <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                        <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                        <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>  
            <MDBCol md={4}>
                <MDBCard className="shadow-1 card-loading-width mt-4" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', borderRadius: '15px'}}>
                    <MDBCardBody style={{borderRadius: '15px'}}>
                        <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                        <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                        <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                        <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol> 
            <MDBCol md={4}>
                <MDBCard className="shadow-1 card-loading-width mt-4" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', borderRadius: '15px'}}>
                    <MDBCardBody style={{borderRadius: '15px'}}>
                        <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                        <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                        <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                        <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>                                    
        </MDBRow>
    );
}

function VeiculosSimpleListPreloader({style, className}){

    return(
    <>
            <div style={style} className={className + ''}>
                <div style={{paddingLeft: '4.3rem', paddingRight: '4.3rem'}}>
                    <div style={{width: '100%', height: '1rem', marginTop: '1rem'}} className='is-loading'></div>
                    <div style={{width: '100%', height: '1rem', marginTop: '1rem'}} className='is-loading'></div>
                    <div style={{width: '100%', height: '1rem', marginTop: '1rem'}} className='is-loading'></div>   
                </div>            
            </div>        
    </>
    );
}

function VeiculosPreloader({style, className}){


        return (    
            <div style={style} className={className + ''}>
                <MDBCol md={4} style={{display: 'inline-block'}}>
                    <MDBCard className="shadow-1 card-loading-width" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px'}}>
                        <MDBCardBody style={{borderRadius: '15px'}}>
                            <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                            <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                            <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                            <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>  
                <MDBCol md={4} style={{display: 'inline-block'}}>
                    <MDBCard className="shadow-1 card-loading-width" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px'}}>
                        <MDBCardBody style={{borderRadius: '15px'}}>
                            <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                            <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                            <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                            <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol> 
                <MDBCol md={4} style={{display: 'inline-block'}}>
                    <MDBCard className="shadow-1 card-loading-width" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px'}}>
                        <MDBCardBody style={{borderRadius: '15px'}}>
                            <div style={{width: '60%', height: '1.4rem'}} className='is-loading'></div>
                            <div style={{width: '40%', height: '2rem', marginTop: '2rem'}} className='is-loading'></div>
                            <div style={{width: '80%', height: '2rem', marginTop: '0.5rem'}} className='is-loading'></div>
                            <div style={{width: '50%', height: '2.5rem', marginTop: '2.5rem', borderRadius: '30px'}} className='is-loading'></div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>                                    
            </div>
        );
}

export { VeiculosPreloader, VeiculosDashPreLoader, VeiculosSimpleListPreloader, VeiculoPreloader, DashboardPreloader, RegisterLoader, GalleryUpdateLoader, DeleteLoader };