import { useEffect } from 'react'; 
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import NavBar from '../component/navbar';
import Footer from '../component/footer';
import BannerBackground from '../img/Osmani-Foto.jpg';
import SobreBackground from '../img/Osmani-Empresa.jpg';
import WppIcon from '../img/whatsapp.png'
import '../index.css';
import 'swiper/css';

function Empresa () {

    useEffect(() => {
        document.title = "Empresa | Caminhões";
        window.scrollTo(0, 0);

    },[]);

    return (
        <div>
            <NavBar/>
            <MDBContainer style={{maxWidth:'100%', paddingLeft:'0', paddingRight:'0'}}>
                <MDBCarousel style={{top:'-5rem', zIndex:'-1'}}>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={1}
                        src={BannerBackground}
                    />
                </MDBCarousel>
            </MDBContainer>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <h1 style={{color: '#1d3a6c', fontWeight: 600}}>Sobre a Osmani Caminhões</h1>
                        <p>Compra, Venda, Troca e Financiamento de Caminhões. Há 12 Anos no Mercado.</p>
                        <p>Negocie seu caminhão com a garantia de quem já vendeu mais de 2.000 e financiou mais de 10.000 caminhões.</p>
                        <p>Aqui você faz o melhor negócio, com as melhores condições, seja para comprar, vender, trocar ou financiar seu caminhão ou utilitário, com garantia e transparência.</p>
                    </MDBCol>
                    <MDBCol className='gradient-bg' style={{"--img": (`url(${SobreBackground})`), backgroundRepeat: 'no-repeat'}}></MDBCol>
                </MDBRow>
                <MDBRow style={{maxWidth:'100%', paddingLeft:'0', paddingRight:'0', margin:'0', marginTop:'3rem', marginBottom:'3rem'}}>
                    <MDBCol style={{maxWidth:'100%', paddingLeft:'0', paddingRight:'0'}}>
                        <div style={{backgroundColor:'#c0c0c0', width:'100%', height:'1px'}}></div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <h1 style={{color: '#1d3a6c', fontWeight: 600, textAlign:'center'}}>Conheça nossa Equipe</h1>
                </MDBRow>
                <MDBRow style={{marginTop:'2rem'}}>
                    <MDBCol style={{textAlign:'center'}}>
                        <h5 style={{color: '#1d3a6c'}}>Thiago Osmani</h5>
                        <h6>COMERCIAL</h6>
                        <a href='https://web.whatsapp.com/send?phone=5511986663820' className='wpp-btn' style={{marginTop:'1rem'}}>
                            <img src={WppIcon} style={{width:'18px', height:'18px', marginRight:'5px'}}></img>
                            <div style={{color: '#FBFBFB'}}>11 9 8666-3820</div>
                        </a>
                    </MDBCol>
                    <MDBCol style={{textAlign:'center'}}>
                        <h5 style={{color: '#1d3a6c'}}>Filipe</h5>
                        <h6>COMERCIAL</h6>
                        <a href='https://web.whatsapp.com/send?phone=5511959772012' className='wpp-btn' style={{marginTop:'1rem'}}>
                            <img src={WppIcon} style={{width:'18px', height:'18px', marginRight:'5px'}}></img>
                            <div style={{color: '#FBFBFB'}}>11 9 5977-2012</div>
                        </a>
                    </MDBCol>
                    <MDBCol style={{textAlign:'center'}}>
                        <h5 style={{color: '#1d3a6c'}}>Danilo Fernandes</h5>
                        <h6>COMERCIAL</h6>
                        <a href='https://web.whatsapp.com/send?phone=5511947789159' className='wpp-btn' style={{marginTop:'1rem'}}>
                            <img src={WppIcon} style={{width:'18px', height:'18px', marginRight:'5px'}}></img>
                            <div style={{color: '#FBFBFB'}}>11 9 4778-9159</div>
                        </a>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{maxWidth:'100%', paddingLeft:'0', paddingRight:'0', margin:'0', marginTop:'3rem', marginBottom:'3rem'}}>
                    <MDBCol style={{maxWidth:'100%', paddingLeft:'0', paddingRight:'0'}}>
                        <div style={{backgroundColor:'#c0c0c0', width:'100%', height:'1px'}}></div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    );
}

export default Empresa;