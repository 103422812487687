import { MDBContainer, MDBRow, MDBCol, MDBBtn} from "mdb-react-ui-kit";
import { useState } from "react";
import Select from 'react-select'
import { GalleryUpdate } from "./gallery_update";
import { BsPencilSquare } from 'react-icons/bs';
import { RegisterLoader } from "./preloader";
import { api } from "../controllers/api-controller";

function EditVeiculo({ id }) {

    const [select, setSelect] = useState(false)
    const [value, setValue] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const update = async () => {

        if (!select && !value && !id) {
            return
        }

        setStatusLoading(true);

        let headersList = {
            mode: 'cors',
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = JSON.stringify({
            "id": id, 'tipo': select.value,
            "valor": value,
        });

        try {

            let response = await fetch(`${api}/updateveiculo`, {
                method: "POST",
                body: bodyContent,
                headersList
            });

            let data = await response.json();

            if (data.status === true) {
                setUpdated(true);
                setStatusLoading(false);
            } else {
                setUpdated('error');
                setStatusLoading(false);
            }

        } catch (e) {
            //console.log(e);
            setUpdated('error');
            setStatusLoading(false);
        }

    }

    const options = [
        { value: 'nome_veiculo', label: 'Nome do veiculo' },
        { value: 'cidade_localizacao', label: 'Localização do veiculo' },
        { value: 'detalhes_veiculo', label: 'Descrição do veiculo' },
        { value: 'ano_veiculo', label: 'Ano do veiculo' },
        { value: 'ano_modelo', label: 'Ano do modelo' },
        { value: 'tracao', label: 'Tração do veiculo' },
        { value: 'quilometragem', label: 'Quilometragem do veiculo' },
        { value: 'carroceria', label: 'Tipo de carroceria do veiculo' },
        { value: 'cor_veiculo', label: 'Cor do veiculo' },
        { value: 'valor_veiculo', label: 'Valor do veiculo' },
        { value: 'fotos', label: 'Fotos do veiculo' },
    ]

    return (
        <>
            <MDBContainer>
                <MDBRow className="justify-content-center">
                    <MDBCol md={11}>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem' }}>
                                <BsPencilSquare size={'2rem'} color='#1d3a6c' />
                            </div>
                            <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0 }}>
                                <span style={{ paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #1d3a6c', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1.1rem' }}>
                                    Editar Veiculo
                                </span> <br />
                                Altere as informações relacionadas ao veiculo selecionado.</p>
                        </div>
                    </MDBCol>
                    <MDBCol md={11}>
                        <label style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem' }} className='mt-4'>Para começar, selecione o que você deseja alterar no veiculo:</label>
                        <Select className="mt-1" onChange={e => setSelect(e)} options={options} placeholder={'Selecione uma opção'} />
                    </MDBCol>
                    <MDBCol md={11}>
                        <hr />
                    </MDBCol>
                    {select ?
                        <>
                            {select.value !== 'fotos' ?
                                <MDBCol md={11}>
                                    {!statusLoading ?
                                        <>
                                            <label style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem', }}>{select.label}</label>
                                            <textarea onChange={e => setValue(e.target.value)} rows={2} placeholder="Digite aqui sua alteração para essa opção..." style={{
                                                backgroundColor: '#f5f5f5', borderColor: 'transparent',
                                                width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'
                                            }} />
                                            <MDBBtn onClick={() => update()} style={{ width: '100%', marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem', }} outline>Salvar alteração</MDBBtn>
                                            {updated === 'error' && <p className="text-center" style={{fontSize: '0.9rem', color: 'red', marginTop: '1rem'}}>Houve um erro ao atualizar, tente novamente.</p>}
                                            {updated && <p className="text-center" style={{fontSize: '0.9rem', color: 'green', marginTop: '1rem'}}>Informações atualizadas com sucesso.</p>}
                                        </>
                                        :
                                        <RegisterLoader />
                                    }
                                </MDBCol>
                                :
                                <MDBCol md={12}>
                                    <GalleryUpdate id={id} />
                                </MDBCol>
                            }
                        </>
                        :
                        null
                    }
                </MDBRow>
            </MDBContainer>
        </>
    );
}

export { EditVeiculo }