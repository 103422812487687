import { useState } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem } from 'mdb-react-ui-kit';
import { IoHomeOutline } from "react-icons/io5";
import { FaRegBuilding } from "react-icons/fa";
import { BsTruck } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { RiLogoutCircleLine } from "react-icons/ri";
import { CiShoppingTag } from "react-icons/ci";
import Cookies from 'js-cookie';
import LogoImg from '../img/osmaniDefaultLogo.png';

function NavBar() {
  const [showNavCentred, setShowNavCentred] = useState(true);

  const toggleNav = () => setShowNavCentred(!showNavCentred);

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-main">
          <img src={LogoImg} style={{ padding: '15px', paddingLeft: '2rem' }} className="logo-navbar" />
          <MDBNavbar expand='lg' sticky style={{ textTransform: 'uppercase', fontFamily: 'M PLUS 1', maxWidth: '100%' }} className="shadow-0">
            <MDBContainer style={{ maxWidth: '100%', alignContent: 'left', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <MDBNavbarToggler
                type='button'
                data-target='#navbarCenteredAlignExample'
                aria-controls='navbarCenteredAlignExample'
                aria-expanded='true'
                aria-label='Toggle navigation'
                onClick={toggleNav}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>
              <MDBCollapse navbar show={showNavCentred}>
                <MDBNavbarNav right fullWidth={true} className='mb-2 mb-lg-0' style={{ width: '100%' }}>
                  <MDBNavbarItem>
                    <Link to='/'>
                      <span className='nav-btns'>
                        <IoHomeOutline />&nbsp; Início
                      </span>
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <Link to='/empresa' style={{ border: 'none' }}>
                      <span className='nav-btns'>
                        <FaRegBuilding />&nbsp; Empresa
                      </span>
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a href='/catalogo' style={{ border: 'none' }}>
                      <span className='nav-btns'>
                        <BsTruck />&nbsp; Catalogo
                      </span>
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a href='https://www.osmanicaminhoes.com.br/15/Satisfacao.aspx' style={{ border: 'none' }}>
                      <span className='nav-btns'>
                        <CiShoppingTag />&nbsp; Vendidos
                      </span>
                    </a>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </div>
      </div>
    </>
  );
}

function NavbarVeiculo() {
  return (
    <MDBNavbar expand='lg' style={{ backgroundColor: '#fbfbfb30', width: '100%', backdropFilter: 'blur(25px)', borderBottomStyle: 'solid', borderBottomColor: '#1d3a6c90', zIndex: '300' }} className="shadow-0">
      <MDBContainer>
        <MDBNavbarBrand href='/'>
          <img
            src={LogoImg}
            height={'auto'}
            width={'130px'}
            alt='logo'
            loading='lazy'
          />
        </MDBNavbarBrand>

      </MDBContainer>
    </MDBNavbar>
  );
}

function NavbarDashboard({ setView }) {
  const [showNavCentred, setShowNavCentred] = useState(false);

  const toggleNav = () => setShowNavCentred(!showNavCentred);

  const logout = () => {
    Cookies.remove('authkey');
    setView('login');
  }

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-main">
          <img src={LogoImg} style={{ padding: '15px', paddingLeft: '2rem' }} className="logo-navbar" />
          <MDBNavbar expand='lg' sticky style={{ textTransform: 'uppercase', fontFamily: 'M PLUS 1', maxWidth: '100%' }} className="shadow-0">
            <MDBContainer style={{ maxWidth: '100%', alignContent: 'left', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <MDBNavbarToggler
                type='button'
                data-target='#navbarCenteredAlignExample'
                aria-controls='navbarCenteredAlignExample'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={toggleNav}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>
              <MDBCollapse navbar show={showNavCentred} center>
                <MDBNavbarNav right fullWidth={true} className='mb-2 mb-lg-0' style={{ width: '100%' }}>
                  <MDBNavbarItem onClick={() => setView('dashboard')} style={{cursor: 'pointer'}}>
                      <span className='nav-btns'>
                        <IoHomeOutline />&nbsp; Início
                      </span>
                  </MDBNavbarItem>
                  <MDBNavbarItem onClick={() => setView('adicionar_veiculo')} style={{cursor: 'pointer'}}>
                      <span className='nav-btns'>
                        <IoMdAdd />&nbsp; Adicionar Veículo
                      </span>
                  </MDBNavbarItem>
                  <MDBNavbarItem onClick={() => setView('gerenciar_veiculo')} style={{cursor: 'pointer'}}>
                    <span className='nav-btns'>
                      <BsTruck />&nbsp; Gerenciar Veículos
                    </span>
                  </MDBNavbarItem>
                  <MDBNavbarItem style={{cursor: 'pointer'}} onClick={logout}>
                      <span className='nav-btns'>
                        <RiLogoutCircleLine />&nbsp; Desconectar
                      </span>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </div>
      </div>
    </>
  );
}

export default NavBar;

export { NavbarVeiculo, NavbarDashboard };